import{Injectable}from'@angular/core';

const USER_KEY = 'auth-user';

@Injectable({
providedIn: 'root'
})
export class StorageService {
constructor() {}

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getToken(): any {
    const tokenType  = 'Bearer ';
    const jsonToken = this.getUser();
    //window.alert(JSON.stringify(jsonToken));
    if (jsonToken) {
      return tokenType + jsonToken.jwttoken;
    }
    return "";
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    //window.alert(JSON.stringify(user));
    if (user) {
      return true;
    }
    return false;
  }

    isUserInRole(roles: string, crole: string) {
        const roleArray = roles.split(",");
        for (let role of roleArray) {
            if (role === crole) {
                return true;
            }
        }
        return false;
    }

  public isRoleUser(): boolean {
    return this.isUserInRole(this.getUser().roles, "USER") || this.isUserInRole(this.getUser().roles, "ADMIN");
  }

  public isRoleAdmin(): boolean {
    return this.isUserInRole(this.getUser().roles, "ADMIN");
  }

  public isRoleReport(): boolean {
    return this.isUserInRole(this.getUser().roles, "REPORT") || this.isUserInRole(this.getUser().roles, "ADMIN");
  }
}
