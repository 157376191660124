import { Component, OnInit } from '@angular/core';
import { FormGroup , FormControl,Validators,FormGroupDirective,FormBuilder} from '@angular/forms';
import {AuthService} from '../_services/auth.service';
import {StorageService} from '../_services/storage.service';
import {Router}from '@angular/router';

import {RegisterService}from './register.service';
import {User, Practice, Calendar, Environment} from '../patient/patient';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  environments: any = [];
  environment!: Environment;
  practices: any = [];
  practice!: Practice;
  calendars: any = [];
  calendar!: Calendar;
  user!: User;
  userDetails1!: FormGroup;
  userDetails2!: FormGroup;
  userDetails3!: FormGroup;
  userDetails4!: FormGroup;
  userDetails5!: FormGroup;
  userDetails1_step = false;
  userDetails2_step = false;
  userDetails3_step = false;
  userDetails4_step = false;
  userDetails5_step = false;
  userDetails2_disp = false;
  userDetails3_disp = false;
  environmentDefault = 1;
  environmentDisp = false;
  step = 1;

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  localstorageService: any;

  constructor(private formBuilder: FormBuilder, public restApi: RegisterService, private router: Router, private authService: AuthService, private storageService: StorageService) {
    this.localstorageService = this.storageService;
  }

  ngOnInit() {
        this.userDetails1 = this.formBuilder.group({
            versionId: ['', Validators.required],
            url: ['', Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
            token: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9-]+$')])]
        });

        this.userDetails2 = this.formBuilder.group({
            practiceId: ['', Validators.required],
            clientName1: ['',Validators.required],
            clientName2: [''],
            clientAddress1: ['',Validators.required],
            clientAddress2: ['',Validators.required]
        });

        this.userDetails3 = this.formBuilder.group({
            clientId: ['', Validators.required],
            calendarId: ['', Validators.required],
            clientShortName: ['',Validators.required]
        });

        this.userDetails4 = this.formBuilder.group({
            clientPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9-+ ]+$')])],
            clientProviderCode: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.-]+$')])],
            clientVatNumber: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+[0-9 ]+$')])]
        });

        this.userDetails5 = this.formBuilder.group({
            username: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]+[a-zA-Z ]+$')])],
            userid: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.-]+$')])],
            passwd: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.-@$]+$')])]
            //passwd: ['', Validators.compose([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{5,}')])]
        });

        /*
        this.restApi.getParams().subscribe(data => {
            for (var i = 0; i < data.length; i++) {
                let myObj = JSON.parse(JSON.stringify(data[i]));
                if (myObj.param === "VERSION") { this.userDetails1.controls["versionId"].setValue(myObj.value); }
                if (myObj.param === "URL") { this.userDetails1.controls["url"].setValue(myObj.value); }
            }
        });
        */
        this.restApi.getEnvironments().subscribe(data => {
          //window.alert(JSON.stringify(data));
          if(JSON.parse(JSON.stringify(data)).hasOwnProperty('Message')) {
              window.alert(JSON.parse(JSON.stringify(data)).Message);
              return;
          } else {
              this.environments = JSON.parse(JSON.stringify(data));
              this.environment = this.environments.filter((environment: any) => environment.id == this.environmentDefault)[0];
              //window.alert(JSON.stringify(this.environment));
              this.userDetails1.controls["versionId"].setValue(this.environment.versionId);
              this.userDetails1.controls["url"].setValue(this.environment.url);
              if(this.environments.length > 1) { this.environmentDisp=true; }
          }
        });
  }

  //get getUser { return this.user.controls; }
  get getUserDetails1() { return this.userDetails1.controls; }
  get getUserDetails2() { return this.userDetails2.controls; }
  get getUserDetails3() { return this.userDetails3.controls; }
  get getUserDetails4() { return this.userDetails4.controls; }
  get getUserDetails5() { return this.userDetails5.controls; }

  next(){
    //window.alert(this.step);
    if(this.step==1){
          this.userDetails1_step = true;
          if (this.userDetails1.invalid) { return  }
          this.user = {
                versionId: this.userDetails1.controls["versionId"].value,
                url: this.userDetails1.controls["url"].value,
                email: this.userDetails1.controls["email"].value,
                token: this.userDetails1.controls["token"].value,
                userid: this.userDetails1.controls["email"].value,
                passwd: this.userDetails1.controls["token"].value
          };

          let rst1 = this.restApi.getPractices(this.user).subscribe(data => {
            //window.alert(JSON.stringify(data));
            if(JSON.parse(JSON.stringify(data)).hasOwnProperty('Message')) {
                window.alert(JSON.parse(JSON.stringify(data)).Message);
                return;
            } else if(JSON.parse(JSON.stringify(data)).hasOwnProperty('message')) {
                window.alert(JSON.parse(JSON.stringify(data)).message);
                return;
            } else {
                this.practices = JSON.parse(JSON.stringify(data)).practices;
                window.alert(JSON.stringify(this.practices));
                window.alert(this.practices.length);
                if(this.practices.length > 0) {
                    let rst4 = this.restApi.sendUser(this.user).subscribe(data1 => {
                        //-------------------------------------
                        this.authService.login(this.user.userid, this.user.passwd).subscribe({
                        //this.authService.login("xxxxx", "Abcd4!").subscribe({
                          next: data2 => {
                            window.alert(JSON.stringify(data2));
                            this.storageService.saveUser(data2);
                            this.isLoginFailed = false;
                            this.isLoggedIn = true;
                            //this.reloadPage();
                          },
                          error: err => {
                            this.errorMessage = err.error.message;
                            this.isLoginFailed = true;
                          }
                        });
                    });
                    //--------------------------------------

                  this.step++;
                } else {
                  window.alert("Veuillez revérifier E-mail/Token ou vous rapprocher de votres gestionnaire de calendrier.");
                  return;
                }
            }
          });
    }
    //window.alert(this.step);
    if(this.step==2){
        this.userDetails2_step = true;
        if (this.userDetails2.invalid) { return }
        this.user = {
                      versionId: this.userDetails1.controls["versionId"].value,
                      url: this.userDetails1.controls["url"].value,
                      email: this.userDetails1.controls["email"].value,
                      token: this.userDetails1.controls["token"].value,
                      practiceId: this.userDetails2.controls["practiceId"].value,
                      userid: "xxx",
                      passwd: "xxx"
        };
        //window.alert(JSON.stringify(this.user));
        let rst2 = this.restApi.getCalendars(this.user).subscribe(data => {
            if(JSON.parse(JSON.stringify(data)).hasOwnProperty('message')) {
                window.alert(JSON.parse(JSON.stringify(data)).message);
                return;
            } else {
                this.calendars = JSON.parse(JSON.stringify(data)).calendars;
            //window.alert(JSON.stringify(this.practices));
                if(this.calendars.length > 0) {
                  this.step++;
                } else {
                  window.alert("Veuillez revérifier E-mail/Token ou vous rapprocher de votre gestionnaire de calendrier.");
                  return;
                }
            }
            //window.alert(JSON.stringify(data));
        });
    }
    if(this.step==3){
        this.userDetails3_step = true;
        if (this.userDetails3.invalid) { return }
        this.step++;
    }
    if(this.step==4){
        this.userDetails4_step = true;
        if (this.userDetails4.invalid) { return }
        this.step++;
    }
  }

  selectPractice(cpractice: Practice){
    this.userDetails2_disp = true;
    this.practice = cpractice;
    //window.alert(JSON.stringify(cpractice));
    this.userDetails2.controls["practiceId"].setValue(cpractice.id);
    this.userDetails2.controls["clientName1"].setValue(cpractice.name);
    this.userDetails5.controls["username"].setValue(cpractice.name);
    this.userDetails2.controls["clientAddress1"].setValue(cpractice.addressLine1);
    this.userDetails2.controls["clientAddress2"].setValue(cpractice.zipcode+" "+cpractice.city);
    //this.userDetails2.controls["clientAddress2"].setValue(cpractice.zipcode+" "+cpractice.city+" "+cpractice.country);
  }

  selectEnvironment(cenvironment: Environment){
    this.userDetails1.controls["versionId"].setValue(cenvironment.versionId);
    this.userDetails1.controls["url"].setValue(cenvironment.url);
  }

  selectCalendar(ccalendar: Calendar){
    this.userDetails3_disp = true;
    this.calendar = ccalendar;
    //window.alert(JSON.stringify(cpractice));
    this.userDetails3.controls["clientId"].setValue(ccalendar.id);
    this.userDetails3.controls["calendarId"].setValue(ccalendar.id);
    this.userDetails3.controls["clientShortName"].setValue(ccalendar.agendaName);
  }

  previous(){
    this.step--
    if(this.step==1){
      this.userDetails1_step = false;
    }
    if(this.step==2){
      this.userDetails3_step = false;
    }
    if(this.step==3){
      this.userDetails4_step = false;
    }
    if(this.step==4){
      this.userDetails5_step = false;
    }
  }

  submit(){
    if(this.step==5){
      this.userDetails5_step = true;
      if (this.userDetails5.invalid) { return }
      this.user = {
                    versionId: this.userDetails1.controls["versionId"].value,
                    url: this.userDetails1.controls["url"].value,
                    email: this.userDetails1.controls["email"].value,
                    token: this.userDetails1.controls["token"].value,
                    practiceId: this.userDetails2.controls["practiceId"].value,
                    clientName1: this.userDetails2.controls["clientName1"].value,
                    clientName2: this.userDetails2.controls["clientName2"].value,
                    clientAddress1: this.userDetails2.controls["clientAddress1"].value,
                    clientAddress2: this.userDetails2.controls["clientAddress2"].value,
                    clientId: this.userDetails3.controls["clientId"].value,
                    calendarId: this.userDetails3.controls["calendarId"].value,
                    clientShortName: this.userDetails3.controls["clientShortName"].value,
                    clientProviderCode: this.userDetails4.controls["clientProviderCode"].value,
                    clientVatNumber: this.userDetails4.controls["clientVatNumber"].value,
                    clientPhone: this.userDetails4.controls["clientPhone"].value,
                    username: this.userDetails5.controls["username"].value,
                    userid: this.userDetails5.controls["userid"].value,
                    passwd: this.userDetails5.controls["passwd"].value
      };
      //window.alert(JSON.stringify(this.user));
      let rst4 = this.restApi.sendUser(this.user).subscribe(data1 => {
        //this.reloadPage();
        /*
        window.alert(JSON.stringify(data1));
            this.storageService.saveUser(data1);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            setTimeout(() => {
              this.reloadPage();
            }, 10);
            */
        //-------------------------------------
        this.authService.login(this.user.userid, this.user.passwd).subscribe({
        //this.authService.login("xxxxx", "Abcd4!").subscribe({
          next: data => {
            this.storageService.saveUser(data);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.reloadPage();
          },
          error: err => {
            this.errorMessage = err.error.message;
            this.isLoginFailed = true;
          }
        });
        //--------------------------------------
      });
    }
  }

  reloadPage(): void {
    //this.router.navigate(['login']);
    this.router.navigate(['patient']);
  }
}
