import{Injectable}from'@angular/core';
import {HttpClient }from '@angular/common/http';
import {Observable}from 'rxjs';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

const API_URL = environment.baseURL;

@Injectable({
providedIn: 'root',
})
export class UserService {
constructor(private http: HttpClient) {}

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + '/patient', { responseType: 'text' });
  }

}
