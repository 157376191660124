import{Component, OnInit}from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule}from '@angular/forms';
import {ValidateForm}from '../shared/validateForm.validator';
import {timer}from 'rxjs';
import {Router, ActivatedRoute, Params}from '@angular/router';

import * as moment from 'moment';

import {animate, state, style, transition, trigger}from '@angular/animations';
import {DatePipe}from '@angular/common';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";
import {MatTableDataSource}from "@angular/material/table";

import {Patient, Bill, VisitReason}from './patient';
import {PatientService}from './patient.service';

import {CurrencyFormatterDirective}from "../shared/currency-formatter.directive";
import {StorageService}from '../_services/storage.service';
import {AuthService}from '../_services/auth.service';

const AUTH_API = environment.baseURL ;

@Component({
selector: 'app-patient',
templateUrl: './patient.component.html',
styleUrls: ['./patient.component.css'],
animations: [
trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: false,
})
export class PatientComponent {
  public displayedColumns = ['patientFirstName', 'patientLastName', 'birthday', 'email', 'mobilePhone', 'startDate', 'endDate', 'status' ];
  public displayedColumnsCaisse = ['firstName', 'datePaiement', 'cash' ];
  public displayedColumnsCompte = ['firstName', 'datePaiement', 'cash', 'payconiq', 'vat', 'priceWVat' ];
  public displayedColumnsUnpaid = ['firstName', 'dateService', 'price', 'vat', 'priceWVat' ];
  public displayedColumnsWithExpand = [...this.displayedColumns, 'expand' ];
  patients: any = [];
  bills: any = [];
  billsCompte: any = [];
  billsUnpaid: any = [];
  public patientsDataSource = new MatTableDataSource<Patient>();
  public billsDataSource = new MatTableDataSource<Patient>();
  public billsCompteDataSource = new MatTableDataSource<Patient>();
  public billsUnpaidDataSource = new MatTableDataSource<Patient>();
  public visitReasons: any = [];
  public billableVisitReasons: any = [];
  isLoading = false;
  isLoadingCaisse = false;
  isLoadingCompte = false;
  isBillEnabled = true;
  selectedPaid = 0;
  public selectedDate = moment((new Date()).toISOString()).format('YYYY-MM-DD');
  public dateCaisse = '';
  public dateCompte = '';
  public bilApiUrl = '';
  expandedElement: Patient | null;
  public cappointment = 0;
  public cordre = 0;
  public cprice = '0';
  public cvat = '0';
  public cpaid = 1;
  public ccash = '0';
  public cpayconiq = '0';
  public cstatus = '';
  public cvisitReasonId = 0;
  public cinc = 0;
  public cvisitReasonName = '';
  public cpriceWVat = '0';
  public chidden = true;
  public addDisabled = true;
  public cvisitReason = null;
  public cbills: any = [];
  localstorageService: any;

  rangeCompte = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  rangeCaisse = new FormGroup({
    startCaisse: new FormControl<Date | null>(null),
    endCaisse: new FormControl<Date | null>(null),
  });
  rangeUnpaid = new FormGroup({
    startUnpaid: new FormControl<Date | null>(null),
    endUnpaid: new FormControl<Date | null>(null),
  });

  defaultSelected = 0;
  selection: number = 0;

  constructor(public restApi: PatientService, private datePipe: DatePipe, private storageService: StorageService,
            private authService: AuthService, private router: Router) {
    this.localstorageService = this.storageService
    this.bilApiUrl = AUTH_API + common.API_BILLING + "/appointment/bill/generate/";
    this.expandedElement = null;
    this.loadAppointments(this.selectedDate, "ALL");
  }

  submitForm(appId: number, inc: number, visitReasonId: number, visitReasonName: string, ordre: number, price: string, vat: string, paid: number, cash: string, payconiq: string) {
    this.openBill(appId, inc, visitReasonId.toString(), visitReasonName, ordre.toString(), price, vat, paid.toString(), cash, payconiq);
  }

  openBill(appId: number, inc: number, visitReasonId: string, visitReasonName: string, ordre: string, price: string, vat: string, paid: string, cash: string, payconiq: string) {
    this.restApi.openBill(appId.toString(), inc.toString(), visitReasonId.toString(), visitReasonName, ordre.toString(), price, vat, paid.toString(), cash, payconiq, this.storageService.getToken()).then(() => {
      this.authService.refresh().subscribe(data => {
        this.storageService.saveUser(data);
        }
      );
      setTimeout(() => this.loadBillsByApp(appId), 1000);
    });
  }

  loadBillsByApp(appId: number) {
    this.restApi.getBillsByAppId(appId.toString()).subscribe(data => {
        this.cbills = Object.values(data);
        this.chidden=true;
    });
  }

  onDeleteBill(billToDelete: any) {
    //window.alert(JSON.stringify(newValue));
    if(confirm("Attention, cette procedure permet de supprimer seulement la dernière facture du mois.\nEtes-vous sûr de vouloir supprimer cette facture?")) {
      //window.alert(JSON.stringify(billToDelete));
      this.restApi.deleteBill(billToDelete.appointment.toString(), billToDelete.ordre.toString()).subscribe(data => {
          this.cbills = Object.values(data);
          this.chidden=true;
      });
    }
  }

  loadBillsByApp2(appo: any) {
    let appId = appo.id;
    let appIdStr = appId.toString();
    this.restApi.getBillsByAppId(appIdStr).subscribe(data => {
        this.cbills = Object.values(data);
        this.chidden=true;
        //window.alert(JSON.stringify(this.cbills));
        //window.alert(this.cbills.length);
        if(this.cbills.length == 0) {
          let visit = this.getVisitReasonRecord(appo.visitReasonId);
          if(visit != null) {
            this.selectNewService(appId, visit);
            this.chidden=false;
          }
        }
    });
  }

  getVisitReasonRecord(idVisit:number) {
    for (var i = 0; i < this.visitReasons.length; i++) {
        if (this.visitReasons[i].id === idVisit) {
            return this.visitReasons[i];
        }
    }
    return null;
  }

  selectNewService(idApp:number, newValue: any) {
    //window.alert(JSON.stringify(newValue));
    //this.cvisitReason=newValue;
    //this.addDisabled=false;
    this.cappointment = idApp;
    this.cordre = 0;
    this.cprice = newValue.price;
    this.cvat = newValue.vat;
    this.cpaid = 1;
    this.ccash = "0,00";
    this.cpayconiq = newValue.priceWVat;
    //newValue.payconiq;
    this.cvisitReasonId = newValue.id;
    this.cinc = newValue.inc;
    this.cvisitReasonName = newValue.name;
    this.cpriceWVat = newValue.priceWVat;
    this.chidden = false;
  }

  onNgBillChange(newValue: any) {
    //window.alert(newValue);
    //window.alert(JSON.stringify(newValue));
    //this.curBill = JSON.parse(newValue);
    this.cappointment = newValue.appointment != null ? newValue.appointment : newValue.id;
    this.cordre = newValue.ordre != null ? newValue.ordre : 0;
    this.cprice = newValue.price;
    this.cvat = newValue.vat;
    this.cpaid = newValue.paid;
    this.ccash = newValue.cash;
    this.cpayconiq = newValue.paid != 0 ? newValue.payconiq : newValue.priceWVat;
    this.cstatus = newValue.status;
    this.cvisitReasonId = newValue.serviceCode != null ? newValue.serviceCode : newValue.visitReasonId;
    this.cinc = newValue.inc;
    this.cvisitReasonName = newValue.serviceName != null ? newValue.serviceName : newValue.visitReasonName;
    this.cpriceWVat = newValue.priceWVat;
    this.chidden = false;
  }

  onChangeDateCompte(newValue: any) {
    if(this.rangeCompte.value.end != null) {
      this.isLoadingCompte=true;
      //window.alert(JSON.stringify(this.rangeCompte.value));
      let d1 = moment(this.rangeCompte.value.start).format('YYYY-MM-DD');//newValue;
      let d2 = moment(this.rangeCompte.value.end).format('YYYY-MM-DD');//newValue;
      //window.alert(d2);
      //this.loadBillsCompte("1", d1, d2);
      //this.isLoadingCompte=false;
    }
  }

  onChangeDateCompteView(objectStr: any) {
    if(this.rangeCompte.value.end != null) {
      this.isLoadingCompte=true;
      //window.alert(JSON.stringify(this.rangeCompte.value));
      let d1 = moment(objectStr.start).format('YYYY-MM-DD');
      let d2 = moment(objectStr.end).format('YYYY-MM-DD');
      //window.alert(d2);
      this.loadBillsCompte("1", d1, d2);
      this.isLoadingCompte=false;
    }
  }

  onChangeDateCaisse(newValue: any) {
    if(this.rangeCaisse.value.endCaisse != null) {
      this.isLoadingCaisse=true;
      //window.alert(JSON.stringify(this.rangeCaisse.value));
      let d1 = moment(this.rangeCaisse.value.startCaisse).format('YYYY-MM-DD');//newValue;
      let d2 = moment(this.rangeCaisse.value.endCaisse).format('YYYY-MM-DD');//newValue;
      //window.alert(d2);
      this.loadBills("1", d1, d2);
      this.isLoadingCaisse=false;
    }
    /*
    if(newValue != null && newValue != "") {
      this.isLoadingCaisse=true;
      this.dateCaisse = moment(newValue).format('YYYY-MM-DD');//newValue;
      this.loadBills("1", this.dateCaisse, this.dateCaisse);
      this.isLoadingCaisse=false;
    }
    */
  }


  onChangeDateUnpaid(newValue: any) {
    //window.alert(JSON.stringify(this.rangeUnpaid.value));
    //window.alert(this.rangeUnpaid.value.endUnpaid);
    if(this.rangeUnpaid.value.endUnpaid != null) {
      let d1 = moment(this.rangeUnpaid.value.startUnpaid).format('YYYY-MM-DD');//newValue;
      let d2 = moment(this.rangeUnpaid.value.endUnpaid).format('YYYY-MM-DD');//newValue;
      this.loadBillsUnpaid("0", d1, d2);
    }
  }

  onChange(newValue: any) {
    this.selectedDate = moment(newValue).format('YYYY-MM-DD');//newValue;
    this.isLoading = true;
    //this.loadAppointments(this.selectedDate, "CONFIRMED");
    this.loadAppointments(this.selectedDate, "ALL");
    this.isLoading = false;
  }

  postForm(appId: number) {
  }

  loadAppointments(apDate: any, sStatus: string) {
    this.restApi.getAppointments(apDate).subscribe(data => {
        if(sStatus == "ALL") {

          //this.patients = Object.values(data)[0];
          var myobj = Object.values(data)[0];
          this.patients = myobj.filter((patient: any) => patient.status != "CANCELLED");
        } else {
          var myobj = Object.values(data)[0];
          this.patients = myobj.filter((patient: any) => patient.status == sStatus);
        }
        this.patientsDataSource = new MatTableDataSource<Patient>(this.patients);
        this.visitReasons = Object.values(data)[1];
        this.billableVisitReasons = Object.values(data)[2];
        //window.alert(JSON.stringify(this.billableVisitReasons));
    });
  }

  loadBills(sStatus: string, date1: string, date2: string) {
    this.restApi.getBills(sStatus, date1, date2).subscribe(data => {
        var myobj = Object.values(data);
        this.bills = myobj.filter((patient: any) => patient.cash != "0");
        this.billsDataSource = new MatTableDataSource<Patient>(this.bills);
    });
  }

  loadBillsCompte(sStatus: string, date1: string, date2: string) {
    this.restApi.getBills(sStatus, date1, date2).subscribe(data => {
        this.billsCompte = Object.values(data);
        this.billsCompteDataSource = new MatTableDataSource<Patient>(this.billsCompte);
    });
  }

  loadBillsUnpaid(sStatus: string, date1: string, date2: string) {
    this.restApi.getBills(sStatus, date1, date2).subscribe(data => {
        this.billsUnpaid = Object.values(data);
        this.billsUnpaidDataSource = new MatTableDataSource<Patient>(this.billsUnpaid);
    });
  }

  getBill(appId: string, objectStr: any) {
    this.restApi.sendPostData(appId, objectStr).subscribe(data => {
    });
  }

  openCompteXLS(objectStr: any) {
    let d1 = moment(objectStr.start).format('YYYY-MM-DD');
    let d2 = moment(objectStr.end).format('YYYY-MM-DD');
    this.restApi.openCompteXLS(d1, d2, this.storageService.getToken());
  }

  openUnpaidXLS(objectStr: any) {
    let d1 = moment(objectStr.startUnpaid).format('YYYY-MM-DD');
    let d2 = moment(objectStr.endUnpaid).format('YYYY-MM-DD');
    this.restApi.openUnpaidXLS(d1, d2, this.storageService.getToken());
  }

  openCaisseXLS(objectStr: any) {
    //let d1 = moment(objectStr.start).format('YYYY-MM-DD');
    //let d2 = moment(objectStr.end).format('YYYY-MM-DD');
    let d1 = moment(objectStr.startCaisse).format('YYYY-MM-DD');
    let d2 = moment(objectStr.endCaisse).format('YYYY-MM-DD');
    //let d1 = moment(objectStr).format('YYYY-MM-DD');
    this.restApi.openCaisseXLS(d1, d2, this.storageService.getToken());
  }

  checkVisitReasons() {
    if(this.billableVisitReasons.length == 0) {
      //window.alert(this.billableVisitReasons.length);
      window.alert("Veuillez paramétrer les prix des raisons de visite.");
      this.router.navigate(['param']);
    }
  }

  blurCash() {
    //window.alert(this.cpriceWVat);
    var vpriceWVat = this.cpriceWVat;
    var vcash = this.ccash;
    var vpayconiq = this.cpayconiq;
    var ttc = Number(vpriceWVat.toString().replace(",", "."));
    var cash = Number(vcash.toString().replace(",", "."));
    var payconiq = Number(vpayconiq.toString().replace(",", "."));
    var npayconiq = ttc - cash;
    var spayconiq = npayconiq.toString();
    //window.alert(spayconiq.toString().replace(".", ","));
    this.cpayconiq = spayconiq.toString().replace(".", ",");
  }

  blurPayconiq() {
    //window.alert(this.cpriceWVat);
    var vpriceWVat = this.cpriceWVat;
    var vcash = this.ccash;
    var vpayconiq = this.cpayconiq;
    var ttc = Number(vpriceWVat.toString().replace(",", "."));
    var cash = Number(vcash.toString().replace(",", "."));
    var payconiq = Number(vpayconiq.toString().replace(",", "."));
    var ncash = ttc - payconiq;
    var scash = ncash.toString();
    //window.alert(scash.toString().replace(".", ","));
    this.ccash = scash.toString().replace(".", ",");
  }

  clickCash() {
    //var pay=this.cpayconiq;
    //this.ccash=pay;
    //this.cpayconiq='0';
  }

  firstCaseWord(word: string) {
    return word[0].toUpperCase();
  }

  getTodayDate() {
    let d = new Date();
    d.setDate(d.getDate());
    return this.datePipe.transform(d, 'dd-MM-yyyy');
  }
}
