<mat-tab-group>
    <mat-tab label="Dépot documents">
        <mat-card class="example-card">
            <mat-card-content>
                <mat-form-field appearance="outline">
                    <mat-label>Classeur</mat-label>
                    <mat-select #classeur>
                        <mat-option value=""></mat-option>
                        <mat-option value="cl1">Facture d'achat</mat-option>
                        <mat-option value="cl2">Facture de vente</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>receipt</mat-icon>
                </mat-form-field>
                <mat-divider></mat-divider>
                <!--mat-form-field appearance="outline">
                  <mat-label>Fichier</mat-label>
                  <input type="file" class="file-input" multiple matInput />
                </mat-form-field-->
                <mat-form-field style="width: 100%;">
                    <mat-label>Télécharger</mat-label>
                    <button mat-icon-button matPrefix>
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <input matInput readonly type="text"/>
                    <input
                            #f_input
                            hidden
                            multiple
                            type="file"
                    />
                    <mat-error>this field is required</mat-error>
                </mat-form-field>
                <mat-divider></mat-divider>
            </mat-card-content>

            <mat-card-actions align="end">
                <button color="primary" mat-stroked-button>
                    Save
                </button>
            </mat-card-actions>

            <mat-card-footer>
                <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>

    </mat-tab>
    <mat-tab label="Notes de frais">
        <mat-card class="example-card">
            <mat-card-content>
                <mat-form-field appearance="outline">
                    <mat-label>Classeur</mat-label>
                    <mat-select #classeur>
                        <mat-option value=""></mat-option>
                        <mat-option value="cl1">Facture d'achat</mat-option>
                        <mat-option value="cl2">Facture de vente</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>receipt</mat-icon>
                </mat-form-field>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
                <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </mat-tab>
    <mat-tab label="Recherche">
        <mat-card class="example-card">
            <mat-card-content>
                <mat-form-field appearance="outline">
                    <mat-label>Classeur</mat-label>
                    <mat-select #classeur>
                        <mat-option value=""></mat-option>
                        <mat-option value="cl1">Facture d'achat</mat-option>
                        <mat-option value="cl2">Facture de vente</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>receipt</mat-icon>
                </mat-form-field>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-footer>
                <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </mat-tab>
</mat-tab-group>
