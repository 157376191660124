<table width="100%">
  <tr>
    <td width="10%"></td>
    <td>
      <mat-tab-group>
        <mat-tab id="Home" label="{{ 'index.home.label' | translate}}">
          <table class="table-noscroll" width="90%">
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
              <td width="30%">
                <h1>{{ "index.home.message" | translate}}</h1>
                <p>
                  <mat-list role="list">
                    <mat-list-item role="listitem">
                      <mat-icon class="material-icons color_green" matListItemIcon>check
                      </mat-icon>
                      {{ "index.home.doctena" | translate}}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                      <mat-icon class="material-icons color_green" matListItemIcon>check
                      </mat-icon>
                      {{ "index.home.generatedbills" | translate}}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                      <mat-icon class="material-icons color_green" matListItemIcon>check
                      </mat-icon>
                      {{ "index.home.turnover" | translate}}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                      <mat-icon class="material-icons color_green" matListItemIcon>check
                      </mat-icon>
                      {{ "index.home.trackunpaid" | translate}}
                    </mat-list-item>
                    <mat-list-item role="listitem">
                      <mat-icon class="material-icons color_green" matListItemIcon>check
                      </mat-icon>
                      <b>{{ "index.home.freetrial" | translate}}</b>
                    </mat-list-item>
                  </mat-list>
                  <button *ngIf="!localstorageService.isLoggedIn()" color="primary" mat-fab extended color="warn" (click)="redirectTo('register')">
                    <mat-icon>person_add</mat-icon>
                    {{ "index.home.trial" | translate}}
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button *ngIf="!localstorageService.isLoggedIn()" color="primary" mat-fab extended color="success" (click)="redirectTo('login')">
                    <mat-icon>login</mat-icon>
                    {{ "index.home.signin" | translate}}
                  </button>
                </p>
              </td>
              <td width="5%"></td>
              <td height="100%">
                <canvasjs-chart [options]="chartOptions"></canvasjs-chart>
              </td>
            </tr>
          </table>
        </mat-tab>
        <mat-tab id="Contact" label="{{ 'index.contact.label' | translate}}">
          <table width="90%">
            <tr>
              <td></td>
              <td></td>
              <td align="center"><span style="color: green;display:block;">&nbsp;{{contactmsg}}&nbsp;</span></td>
            </tr>
            <tr>
              <td width="15%">
                <h1>{{ "index.contact.textorcall" | translate}} : </h1>
                <p>
                  <mat-list role="list">
                    <mat-list-item role="listitem">
                      {{ "index.contact.email" | translate}} : <a href="mailto:sdksolutions@pt.lu">sdksolutions@pt.lu</a>
                    </mat-list-item>
                    <mat-list-item role="listitem">
                      {{ "index.contact.phone" | translate}} : <a href="tel:+352621177660">+352621177660</a>
                    </mat-list-item>
                  </mat-list>
                </p>
              </td>
              <td width="5%"></td>
              <td>&nbsp;
                <form [formGroup]="contactForm" id="msform">
                  <fieldset class="mat-tab-group-grey">
                    <legend align="center" class="mat-tab-group-grey">{{ "index.contact.contactus" | translate}}</legend>
                    <!--mat-card-->
                    <mat-card-content>
                      <mat-form-field>
                        <mat-label>{{ "index.contact.name" | translate}}</mat-label>
                        <mat-icon matPrefix>person</mat-icon>
                        <input formControlName="name" matInput name="name">
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>{{ "index.contact.email" | translate}}</mat-label>
                        <mat-icon matPrefix>alternate_email</mat-icon>
                        <input formControlName="email" matInput name="email">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>{{ "index.contact.phone" | translate}}</mat-label>
                        <mat-icon matPrefix>phone</mat-icon>
                        <input formControlName="phone" matInput name="phone">
                      </mat-form-field>
                      <mat-form-field>
                        <mat-label>{{ "index.contact.message" | translate}}</mat-label>
                        <mat-icon matPrefix>message</mat-icon>
                        <textarea formControlName="msg" matInput name="msg" placeholder="Votre message"
                                  rows="3"></textarea>
                      </mat-form-field>
                    </mat-card-content>
                    <!--/mat-card-->
                    <mat-card-actions align="end">
                      <button (click)="sendContact()" color="primary" mat-button mat-flat-button
                              type="button">
                        <mat-icon>contact_mail</mat-icon>
                        {{ "index.contact.send" | translate}}
                      </button>
                    </mat-card-actions>
                  </fieldset>
                </form>
              </td>
            </tr>
          </table>
        </mat-tab>
      </mat-tab-group>
    </td>
    <td width="10%"></td>
  </tr>
</table>
