import{Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable}from 'rxjs';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

const AUTH_API = environment.baseURL ;

const httpOptions = {
headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    //window.alert(username + " " + password);
    return this.http.post(
      AUTH_API + common.API_PUBLIC + '/authenticate',
      {
        username,
        password,
      },
      httpOptions
    );
  }

  refresh(): Observable<any> {
    return this.http.post(
      AUTH_API + common.API_USER + '/refreshToken',
      {},
      httpOptions
    );
  }

  logout(): Observable<any> {
    return this.http.post(AUTH_API + common.API_USER + '/logout', { }, httpOptions);
  }
}
