import {Component} from '@angular/core';
import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

import {ClientService}from './client.service';
import {Router}from '@angular/router';


@Component({
selector: 'app-client',
templateUrl: './client.component.html',
styleUrls: ['./client.component.css']
})
export class ClientComponent {
form: any = {
userid: null,
username: null,
email: null,
password: null,
password2: null
};
user: any;
isSubmitFailed = false;

constructor(public restApi: ClientService, private router: Router) {
    this.restApi.getUser().subscribe(data => {
        this.form = Object.values(data);
        this.user = data;
        //window.alert(JSON.stringify(data));
        //window.alert(JSON.stringify(this.form));
    });
  }

  onSubmit() { //userObject: any
    this.restApi.updateUser(this.user).subscribe(data => {
      this.user = data;
      window.alert("Utilisateur mis a jour");
      this.router.navigate(['logout']);
    });
  }
}
