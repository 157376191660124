import{Component}from'@angular/core';

import { environment} from "../../environments/environment";
import {common} from "../../environments/common";
import {MatTableDataSource} from "@angular/material/table";

import {TablesService}from './tables.service';
import {StorageService}from '../_services/storage.service';
import {AuthService}from '../_services/auth.service';


@Component({
selector: 'app-tables',
templateUrl: './tables.component.html',
styleUrls: ['./tables.component.css']
})
export class TablesComponent {
public displayedColumns = ['code', 'name', 'name_bill', 'price', 'vatRate', 'priceWVat'];
public tables: any = [];
public columns: any = [];
public dataSource = new MatTableDataSource<Object>();
public isLoading = false;

constructor(public restApi: TablesService, private storageService: StorageService,
            private authService: AuthService) {
    this.restApi.getTableList().subscribe(data => {
        var myobj = Object.values(data)[0];
        this.tables = myobj.filter((vreason: any) => vreason.isActive == true);
        //window.alert(JSON.stringify(this.tables));
        //this.tables = Object.values(data)[0];
        this.dataSource = new MatTableDataSource<Object>(this.tables);
    });
  }

  loadTable() {

    this.authService.refresh().subscribe(data => {
        this.storageService.saveUser(data);
      }
    );

    this.restApi.getTableList().subscribe(data => {
        var myobj = Object.values(data)[0];
        this.tables = myobj.filter((vreason: any) => vreason.isActive == true);
        //this.tables = Object.values(data)[0];
        this.dataSource = new MatTableDataSource<Object>(this.tables);
    });
  }

  saveData(tbData: any) {
    this.restApi.sendPostData(tbData).subscribe(data => {
      this.loadTable();
    });


  }
  updtData(index: number) {

  }
}
