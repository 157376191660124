import{NgModule}from'@angular/core';
import {BrowserModule }from '@angular/platform-browser';

import {AppRoutingModule}from './app-routing.module';
import {AppComponent}from './app.component';
import {BrowserAnimationsModule}from '@angular/platform-browser/animations';
import {NavbarComponent}from './navbar/navbar.component';

import {MatToolbarModule}from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule}from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule}from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MAT_DATE_LOCALE}from '@angular/material/core';

import {MatProgressBarModule }from '@angular/material/progress-bar';
import {MatDividerModule}from '@angular/material/divider';
import {MatCardModule }from '@angular/material/card';
import {MatNativeDateModule, DateAdapter}from '@angular/material/core';
import {MatDatepickerModule }from '@angular/material/datepicker';
import {MatTableModule}from '@angular/material/table';
import {MatPaginatorModule }from '@angular/material/paginator';
import {MatProgressSpinnerModule}from '@angular/material/progress-spinner';
import {MatSortModule }from '@angular/material/sort';
import {MatCheckboxModule}from '@angular/material/checkbox';
import {MatRadioModule}from '@angular/material/radio';
import {HttpClientModule, HTTP_INTERCEPTORS}from "@angular/common/http";

import {FormsModule, ReactiveFormsModule}from '@angular/forms';
import {MatSidenavModule}from '@angular/material/sidenav';
import {MatMenuModule}from '@angular/material/menu';
import {MatIconModule}from '@angular/material/icon';
import {MatListModule}from '@angular/material/list';
import {RouterModule, Routes}from '@angular/router';
import {MatExpansionModule}from '@angular/material/expansion';
import {MatTooltipModule}from '@angular/material/tooltip';
import {PatientComponent}from './patient/patient.component';
import {TablesComponent}from './param/tables.component';
import {DatePipe, JsonPipe}from '@angular/common';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';

import {animate, state, style, transition, trigger}from '@angular/animations';
import {GetVisitReasonPipe}from './shared/getVisitReason.pipe';
import {GetFrenchDatePipe}from './shared/getFrenchDate.pipe';
import {GetHourFromDatePipe}from './shared/getHourFromDate.pipe';
import {CurrencyPipe}from './shared/currency.pipe';
import {SpinnerComponent}from './spinner/spinner.component';
import {LoadingInterceptor}from './loading.interceptor';
import {CurrencyFormatterDirective}from "./shared/currency-formatter.directive";
import {LoginComponent}from './login/login.component';
import {HomeComponent}from './home/home.component';
import {LogoutComponent}from './logout/logout.component';
import {ClientComponent }from './client/client.component';
import { CustomDateAdapter } from './shared/custom.date.adapter';
import { DocumentComponent } from './document/document.component';
import { FooterComponent } from './footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { IndexComponent } from './index/index.component';

import { TranslationModule } from './translation/translation.module';

@NgModule({
declarations: [
AppComponent,
NavbarComponent,
PatientComponent,
TablesComponent,
GetVisitReasonPipe,
GetFrenchDatePipe,
GetHourFromDatePipe,
CurrencyPipe,
SpinnerComponent,
CurrencyFormatterDirective,
LoginComponent,
HomeComponent,
LogoutComponent,
ClientComponent,
DocumentComponent,
FooterComponent,
RegisterComponent,
IndexComponent
],
imports: [
BrowserModule,
AppRoutingModule,
BrowserAnimationsModule,
MatToolbarModule,
MatButtonModule,

FormsModule,
ReactiveFormsModule,
JsonPipe,
MatSidenavModule,
MatMenuModule,
MatIconModule,
MatListModule,
RouterModule,
MatExpansionModule,
MatTooltipModule,
MatTabsModule,
MatFormFieldModule,
MatInputModule,
MatSelectModule,
MatProgressBarModule,
MatDividerModule,
MatCardModule,
MatNativeDateModule,
MatDatepickerModule,
MatTableModule,
MatSortModule,
MatProgressSpinnerModule,
MatInputModule,
MatPaginatorModule,
MatCheckboxModule,
MatRadioModule,
HttpClientModule,
CanvasJSAngularChartsModule,
TranslationModule,
RouterModule.forRoot([])

  ],
  providers: [
                { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
                DatePipe,
                CurrencyPipe,
                { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
                { provide: DateAdapter, useClass: CustomDateAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
