import{Component, OnInit}from '@angular/core';

import { StorageService } from '../_services/storage.service';
import { AuthService } from '../_services/auth.service';

import {FormBuilder, FormGroup, Validators}from '@angular/forms';
import {IndexService}from './index.service';
import {Contact}from '../patient/patient';
import {MatTabsModule}from '@angular/material/tabs';
import { Router } from '@angular/router';

@Component({
selector: 'app-index',
templateUrl: './index.component.html',
styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
public chartOptions: any;
contactForm!: FormGroup;
isSubmit = true;
submitMessage = "";
tab = "";
contact!: Contact;
contactmsg = "";
localstorageService: any;

constructor(private storageService: StorageService, private authService: AuthService, private formBuilder: FormBuilder, public restApi: IndexService, private router: Router) {
  this.localstorageService = this.storageService;
}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: [''],
      phone: [''],
      email: [''],
      msg: ['', Validators.required]
    });

    this.chartOptions = {
                            "data": [
                                {
                                    "dataPoints": [],
                                    "type": "column"
                                }
                            ],
                            "title": {
                                "text": "Utilisation"
                            }
                        };
    this.restApi.getStatistics().subscribe(data => {
          var cchartOptions = JSON.parse(JSON.stringify(data));
          this.chartOptions = cchartOptions;
    });
  }

  sendContact(){
      if (this.contactForm.invalid) { return }
      this.contact = {
          name: this.contactForm.controls["name"].value,
          phone: this.contactForm.controls["phone"].value,
          email: this.contactForm.controls["email"].value,
          msg: this.contactForm.controls["msg"].value
      }
      this.restApi.sendContact(this.contact).subscribe(data => {
        if(JSON.parse(JSON.stringify(data)).hasOwnProperty('Message')) {
          this.contactmsg = JSON.parse(JSON.stringify(data)).Message;
          //window.alert(JSON.parse(JSON.stringify(data)).Message);
          return;
        } else {
          this.contactForm.controls["name"].setValue("");
          this.contactForm.controls["phone"].setValue("");
          this.contactForm.controls["email"].setValue("");
          this.contactForm.controls["msg"].setValue("");
          this.contactmsg = "Message reçu. Nous vous contacterons dans les meilleurs delais.";
          //window.alert("Message reçu. Nous vous contacterons dans les meilleurs delais.");
        }
      });
  }

  redirectTo(myurl: any) {
    this.router.navigate([myurl]);
  }
}
