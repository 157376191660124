import{Component}from'@angular/core';
import {StorageService }from '../_services/storage.service';
import {AuthService}from '../_services/auth.service';
import {Router}from '@angular/router';


@Component({
selector: 'app-logout',
templateUrl: './logout.component.html',
styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

constructor(private storageService: StorageService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.storageService.clean();
    //window.location.href = "#login";
    this.router.navigate(['login']);
    /*
    this.authService.logout().subscribe({
      next: res => {
        console.log(res);
        this.storageService.clean();
        window.location.reload();
      },
      error: err => {
        console.log(err);
      }
    }); */
  }
}
