<!-- LOGGEDIN -->
<mat-toolbar *ngIf="localstorageService.isLoggedIn()" color="primary">
    <mat-toolbar-row>
        <button mat-icon-button matTooltip="Home" routerLink="/">
            <mat-icon class="material-icons color_black">home</mat-icon>
        </button>
        <a routerLink="/">
            <!--img src="assets/logo30.jpg"-->
            <!--img class="aligned-with-icon" src="assets/logo.svg"-->
            <img class="aligned-with-icon" src="assets/logo2.png">
        </a>
        <span class="toolbar-item-spacer"></span>
        <!--button mat-icon-button matTooltip="Home" routerLink="/">
            <mat-icon class="material-icons color_black">home</mat-icon>
        </button>
        <a routerLink="/">
            <img src="assets/logo30.jpg">
        </a-->
        <span class="aligned-with-icon">{{localstorageService.getUser().clientShortName}}</span>

        <span class="toolbar-item-spacer"></span>
        &nbsp;&nbsp;&nbsp;
        <button *ngIf="localstorageService.isRoleUser()" mat-icon-button matTooltip="{{ 'navbar.calendar' | translate }}" routerLink="patient">
            <mat-icon>people</mat-icon>
        </button>
        <button *ngIf="localstorageService.isRoleAdmin()" mat-icon-button matTooltip="{{ 'navbar.param' | translate }}" routerLink="param">
            <mat-icon>settings</mat-icon>
        </button>
        <span class="toolbar-item-spacer"></span>
        <span class="aligned-with-icon">{{localstorageService.getUser().username}}</span>
        <button [matMenuTriggerFor]="userAccountMenu" mat-icon-button
                matTooltip="{{ 'navbar.account' | translate }}">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #userAccountMenu [overlapTrigger]="false" yPosition="below">
            <button mat-menu-item routerLink="client">
                <mat-icon>verified_user</mat-icon>
                <span>{{ 'navbar.account' | translate }}</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item routerLink="logout">
                <mat-icon>exit_to_app</mat-icon>
                {{ 'navbar.disconnect' | translate }}
            </button>
        </mat-menu>

        <button [matMenuTriggerFor]="langMenu" mat-button>
            <mat-icon svgIcon="{{siteLangCode}}"></mat-icon>
            <span class="aligned-with-icon">{{ siteLanguage }}</span>
        </button>
        <mat-menu #langMenu [overlapTrigger]="false" yPosition="below">
            <ng-container *ngFor="let language of languageList">
                <button mat-menu-item (click)="changeSiteLanguage(language.code)">
                    <mat-icon svgIcon="{{language.code}}"></mat-icon>
                    <span>{{ language.label }}</span>
                </button>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<!-- NOT LOGGEDIN -->
<mat-toolbar *ngIf="!localstorageService.isLoggedIn()" class="mat-toolbar-grey" color="primary">
    <mat-toolbar-row>
        <button mat-icon-button matTooltip="Home" routerLink="/">
            <mat-icon class="material-icons color_black">home</mat-icon>
        </button>
        <a routerLink="/">
            <!--img src="assets/logo30.jpg"-->
            <!--img class="aligned-with-icon" src="assets/logo.svg"-->
            <img class="aligned-with-icon" src="assets/logo3.png">
        </a>
        <span class="toolbar-item-spacer"></span>

        <span class="toolbar-item-spacer"></span>
        &nbsp;&nbsp;&nbsp;
        <button mat-button routerLink="register">
            <mat-icon>person_add</mat-icon>
          {{ "navbar.register"  | translate }}
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button mat-button routerLink="login">
            <mat-icon>login</mat-icon>
          {{ "navbar.connect"  | translate }}
        </button>
        <span class="toolbar-item-spacer"></span>
        <span>&nbsp;</span>
        <button [matMenuTriggerFor]="langMenu" mat-button>
            <mat-icon svgIcon="{{siteLangCode}}"></mat-icon>
            <span class="aligned-with-icon">{{ siteLanguage }}</span>
        </button>
        <mat-menu #langMenu [overlapTrigger]="false" yPosition="below">
            <ng-container *ngFor="let language of languageList">
                <button mat-menu-item (click)="changeSiteLanguage(language.code)">
                    <mat-icon svgIcon="{{language.code}}"></mat-icon>
                    <span>{{ language.label }}</span>
                </button>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>
