import{Injectable}from'@angular/core';
import {HttpClient, HttpHeaders}from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError}from 'rxjs/operators';
import {Router, ActivatedRoute, Params}from '@angular/router';

import { StorageService}from '../_services/storage.service';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

const AUTH_API = environment.baseURL ;

@Injectable({
providedIn: 'root'
})
export class TablesService {

private httpOptions = {
headers: new HttpHeaders({"Content-Type": "application/json"})
  };

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute,
              private storageService: StorageService) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
            //let table = params['table'];
            //this.apiURL = this.apiURL + table;
            //console.log(table);
        });
   }

  // HttpClient API get() method => Fetch employees list
  getTableList(): Observable<Object> {
    const token = this.storageService.getToken();
    //?token="+token;
    const getUrl = AUTH_API + common.API_ADMIN + "/visitReasons";
    return this.http
      .get<Object>(getUrl)
      .pipe(retry(1), catchError(this.handleError));
  }

  sendPostData(body: any) {
      const token = this.storageService.getToken();
      //?token="+token;
      const url = AUTH_API + common.API_ADMIN + "/visitReasons/update";
      const headers = {'Content-Type': 'application/json'};
      const rst = this.http.post<any>(url, body, { headers })
                      .pipe(retry(1), catchError(this.handleError));
      return rst;
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
