<!--div>
    <mat-card>
        <form #f="ngForm"
              (ngSubmit)="f.form.valid && onSubmit()"
              *ngIf="!isLoggedIn"
              name="form"
              novalidate>
            <mat-card-content>
                <h2>Connexion</h2>
                <mat-error *ngIf="f.submitted && isLoginFailed">
                    Utilisateur et/ou mot de passe invalides
                </mat-error>
                <mat-form-field class="full-width-input">
                    <mat-icon matPrefix>person_outline</mat-icon>
                    <input [(ngModel)]="form.username" matInput name="username" placeholder="Utilisateur" required>
                    <mat-error>
                        Saisir un nom utilisateur valide
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width-input">
                    <mat-icon matPrefix>lock_outline</mat-icon>
                    <input [(ngModel)]="form.password" matInput name="password" placeholder="Mot de passe"
                           required type="password">
                    <mat-error>
                        Saisir un mot de passe valide
                    </mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions style="text-align: center;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button [disabled]="!f.form.valid" color="primary" mat-button mat-flat-button>
                    <mat-icon>check</mat-icon>
                    Valider
                </button>
                <a class="btn btn-link" routerLink="/register">Créér compte</a>
            </mat-card-actions>
        </form>
    </mat-card>
</div-->

<table border="0" width="100%">
  <tr><td colspan="3">&nbsp;</td></tr>
  <tr><td colspan="3">&nbsp;</td></tr>
  <tr><td colspan="3">&nbsp;</td></tr>
  <tr><td colspan="3">&nbsp;</td></tr>
    <tr>
        <td width="30%"></td>
        <td width="40%">
            <form #f="ngForm"
                  (ngSubmit)="f.form.valid && onSubmit()"
                  *ngIf="!isLoggedIn"
                  name="form"
                  novalidate id="msform">
            <!--form id="msform"-->
                <fieldset class="mat-tab-group-grey">
                    <legend align="center" class="mat-tab-group-grey">{{ "login.label" | translate}}</legend>
                    <mat-error *ngIf="f.submitted && isLoginFailed">
                      {{ "login.invalid_userpass" | translate}}
                    </mat-error>
                    <!--mat-card-->
                    <mat-card-content>
                        <mat-form-field class="full-width-input">
                            <mat-icon matPrefix>person_outline</mat-icon>
                            <input [(ngModel)]="form.username" matInput name="username" placeholder="{{ 'login.username' | translate}}" required>
                            <mat-error>
                              {{ "login.username_error" | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width-input">
                            <mat-icon matPrefix>lock_outline</mat-icon>
                            <input [(ngModel)]="form.password" matInput name="password" placeholder="{{ 'login.password' | translate}}"
                                   required type="password">
                            <mat-error>
                              {{ "login.password_error" | translate}}
                            </mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <!--/mat-card-->
                    <mat-card-actions align="end">
                      <button [disabled]="!f.form.valid" color="primary" mat-button mat-flat-button>
                        <mat-icon>check</mat-icon>
                        {{ "login.signin" | translate}}
                      </button>
                      <a class="btn btn-link" routerLink="/register">{{ "login.signup" | translate}}</a>
                    </mat-card-actions>
                </fieldset>
            </form>

        </td>
        <td width="30%"></td>
    </tr>
</table>
