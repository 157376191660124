import{Injectable}from'@angular/core';
import {HttpClient, HttpHeaders}from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError}from 'rxjs/operators';
import {Patient}from './patient';
import {Router, ActivatedRoute, Params} from '@angular/router';

import {StorageService}from '../_services/storage.service';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

const AUTH_API = environment.baseURL ;

@Injectable({
providedIn: 'root'
})
export class PatientService {
headers: any;
localstorageService: any;
constructor(private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private storageService: StorageService,
              private router: Router) {
      this.localstorageService = this.storageService;
      this.activatedRoute.queryParams.subscribe((params: Params) => {
            let apDate = params['apDate'];
            //window.alert(apDate);
          this.headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `${storageService.getToken()}`
          })
        });
   }

  sendPostData(appId: string, body: any) {
      const url = AUTH_API + common.API_BILLING + "appointment/bill/"+appId;
      const headers = {'Content-Type': 'application/json'};
      const rst = this.http.post<any>(url, body, { headers })
                      .pipe(retry(1), catchError(this.handleError));
      const billUrl = AUTH_API + common.API_BILLING + "/appointment/bill/get/"+appId;
      setTimeout(() => window.open(billUrl, "_blank"), 10);
      //window.open(billUrl, "_blank");
      return rst;
  }

  openBill(appId: string, inc: string, visitReasonId: string, visitReasonName: string, ordre: string, price: string, vat: string, paid: string, cash: string, payconiq: string, token: string) {
      //const token = this.storageService.getToken();
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      //"?token="+token;
      //window.alert(token);
      form.action = AUTH_API + common.API_BILLING + "/appointment/bill/generate/"+appId+"/"+ordre;
      var params :{ [key: string]: any } = {};
      params = {
        "id": appId,
        "inc": inc,
        "ordre": ordre,
        "visitReasonId": visitReasonId,
        "visitReasonName": visitReasonName,
        "price": price,
        "vat": vat,
        "paid": paid,
        "cash": cash,
        "token": token,
        "payconiq": payconiq
      };
      for (var i in params) {
          if (params.hasOwnProperty(i)) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
          }
      }
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
      return new Promise((resolve, reject) => {
         return resolve(true);
      });
      /* const getUrl = this.apiURL+"bills/"+appId;
      return this.http
        .get<Patient>(getUrl)
        .pipe(retry(0), catchError(this.handleError)); */
  }

  openCompteXLS(d1: string, d2: string, token: string) {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = AUTH_API + common.API_REPORT + "/compte/"+d1+"/"+d2;
      var params :{ [key: string]: any } = {};
      params = {
        "token": token
      };
      for (var i in params) {
          if (params.hasOwnProperty(i)) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
          }
      }
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
  }

  openUnpaidXLS(d1: string, d2: string, token: string) {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = AUTH_API + common.API_REPORT + "/unpaid/"+d1+"/"+d2;
      var params :{ [key: string]: any } = {};
      params = {
        "token": token
      };
      for (var i in params) {
          if (params.hasOwnProperty(i)) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
          }
      }
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
  }

  openCaisseXLS(d1: string, d2: string, token: string) {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = AUTH_API + common.API_REPORT + "/caisse/"+d1+"/"+d2;
      var params :{ [key: string]: any } = {};
      params = {
        "token": token
      };
      for (var i in params) {
          if (params.hasOwnProperty(i)) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
          }
      }
      document.body.appendChild(form);
      form.submit();
      window.open('', 'view');
  }

  // HttpClient API get() method => Fetch patients list
  getAppointments(apDate: any): Observable<Patient> {
    const token = this.storageService.getToken();
    //"?token="+token;
    const getUrl = AUTH_API + common.API_BILLING + "/appointments/"+apDate;
    return this.http
      .get<Patient>(getUrl)
      .pipe(retry(0), catchError(this.handleError));
  }

  getBills(sStatus: string, date1: string, date2: string): Observable<Patient> {
    const token = this.storageService.getToken();
    //"?token="+token;
    const getUrl = AUTH_API + common.API_REPORT + "/bills/"+sStatus+"/"+date1+"/"+date2;
    return this.http
      .get<Patient>(getUrl)
      .pipe(retry(0), catchError(this.handleError));
  }

  getBillsByAppId(appId: string): Observable<Patient> {
    const token = this.storageService.getToken();
    //"?token="+token;
    const getUrl = AUTH_API + common.API_BILLING + "/bills/"+appId;
    return this.http
      .get<Patient>(getUrl)
      .pipe(retry(2), catchError(this.handleError));
  }

  deleteBill(appId: string, ordreId: string): Observable<Patient> {
    const getUrl = AUTH_API + common.API_BILLING + "/bill/delete/"+appId+"/"+ordreId;
    //window.alert(getUrl);
    return this.http
      .get<Patient>(getUrl)
      .pipe(retry(0), catchError(this.handleError));
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    this.router.navigate(['login']);
    return throwError(() => {
      return errorMessage;
    });
  }
}
