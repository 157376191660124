import{NgModule}from'@angular/core';
import {RouterModule, Routes}from '@angular/router';

import {PatientComponent}from './patient/patient.component';
import {TablesComponent}from './param/tables.component';
import {LoginComponent}from './login/login.component';
import {LogoutComponent}from './logout/logout.component';
import {ClientComponent}from './client/client.component';
import {DocumentComponent}from './document/document.component';
import {RegisterComponent} from './register/register.component';
import {IndexComponent} from './index/index.component';

const routes: Routes = [
    {path: 'patient', component: PatientComponent},
    {path: 'param', component: TablesComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'client', component: ClientComponent},
    {path: 'document', component: DocumentComponent},
    {path: 'register', component: RegisterComponent},
    {path: 'index', component: IndexComponent},
    {path: '', redirectTo: 'index', pathMatch: 'full'}
];

@NgModule({
imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
