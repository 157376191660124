import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders}from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {retry, catchError}from 'rxjs/operators';
import {Router, ActivatedRoute, Params}from '@angular/router';

import {environment} from "../../environments/environment";
import {common} from "../../environments/common";

const AUTH_API = environment.baseURL ;

@Injectable({
providedIn: 'root'
})
export class RegisterService {
headers: any;
private httpOptions = {
headers: new HttpHeaders({"Content-Type": "application/json"})
  };

  constructor(private http: HttpClient) {
    this.headers = {'Content-Type': 'application/json'};
  }

  getParams(): Observable<Object[]> {
    const getUrl = AUTH_API + common.API_PUBLIC + "/settings";
    return this.http
      .get<Object[]>(getUrl)
      .pipe(retry(0), catchError(this.handleError));
  }

  getEnvironments(): Observable<Object[]> {
    const getUrl = AUTH_API + common.API_PUBLIC + "/environments";
    return this.http
      .get<Object[]>(getUrl)
      .pipe(retry(0), catchError(this.handleError));
  }

  getPractices(body: any): Observable<Object[]> {
    const headers = {'Content-Type': 'application/json'};
    const postUrl = AUTH_API + common.API_PUBLIC + "/practices";
    //window.alert(JSON.stringify(body));
    return this.http.post<any>(postUrl, body, { headers })
                          .pipe(retry(0), catchError(this.handleError));
  }

  getCalendars(body: any): Observable<Object[]> {
    const headers = {'Content-Type': 'application/json'};
    const postUrl = AUTH_API + common.API_PUBLIC + "/calendars";
    //window.alert(JSON.stringify(body));
    return this.http.post<any>(postUrl, body, { headers })
                          .pipe(retry(0), catchError(this.handleError));
  }

  sendUser(body: any): Observable<Object[]> {
    const headers = {'Content-Type': 'application/json'};
    const postUrl = AUTH_API + common.API_PUBLIC + "/sendUser";
    //window.alert(JSON.stringify(body));
    return this.http.post<any>(postUrl, body, { headers })
                          .pipe(retry(0), catchError(this.handleError));
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
}
