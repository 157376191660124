<mat-card>
    <mat-card-content>
        <form #f="ngForm"
              (ngSubmit)="f.form.valid && onSubmit()"
              name="form"
              novalidate>
            <mat-error *ngIf="f.submitted && isSubmitFailed">
                {{ 'client.auth_error' | translate}}
            </mat-error>
            <mat-form-field>
                <input [(ngModel)]="user.userid" matInput name="userid" placeholder="{{ 'client.userid' | translate}}" readonly>
            </mat-form-field>
            <mat-form-field>
                <input [(ngModel)]="user.username" matInput name="username" placeholder="{{ 'client.username' | translate}}" required>
                <mat-error>
                    {{ 'client.username_error' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input [(ngModel)]="user.email" matInput name="email" placeholder="{{ 'client.email' | translate}}" readonly>
                <mat-error>
                    {{ 'client.email_error' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <input [(ngModel)]="user.passwd" matInput name="passwd" placeholder="{{ 'client.passwd' | translate}}" required
                       type="password">
                <mat-error>
                    {{ 'client.passwd_error' | translate}}
                </mat-error>
            </mat-form-field>
            <button [disabled]="!f.form.valid" color="primary" mat-raised-button>{{ 'client.validate' | translate}}</button>
        </form>
    </mat-card-content>
</mat-card>
