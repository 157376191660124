import{Injectable}from'@angular/core';
//import {HttpRequest, HttpHandler,HttpEvent, HttpInterceptor}from '@angular/common/http';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router}from '@angular/router';
import {Observable, throwError}from 'rxjs';
import {catchError} from 'rxjs/operators';
import {finalize}from 'rxjs/operators';
import {LoaderService}from './loader.service';


import {StorageService}from './_services/storage.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

private totalRequests = 0;

constructor(private loadingService: LoaderService, private storageService: StorageService, private router: Router) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
      if (err.status === 401 || err.status === 403) {
          this.router.navigate(['login']);
          return throwError(err);
      }
      return throwError(err);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log('caught')
    this.totalRequests++;
    this.loadingService.setLoading(true);
    if(this.storageService.isLoggedIn()) {
      request = request.clone({ headers: request.headers.set('Authorization', `${this.storageService.getToken()}`)});
      request = request.clone({ headers: request.headers.set('Accept', 'application/json')});
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json; charset=utf-8')});
    }

    //return next.handle(authReq).pipe(catchError(x=> this.handleAuthError(x)));

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      })
    );
  }
}
