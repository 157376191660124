<div class="row">
    <div class="col-md-12 col-md-offset-5">
        <form [formGroup]="userDetails1" id="msform">
            <!-- progressbar -->
            <ul id="progressbar">
                <li [ngClass]="step>=1 ? 'active ' : 'inactive'">Identifiants</li>
                <li [ngClass]="step>=2 ? 'active' : 'inactive'">Cabinet</li>
                <li [ngClass]="step>=3 ? 'active' : 'inactive'">Calendrier</li>
                <li [ngClass]="step>=4 ? 'active' : 'inactive'">Compléments</li>
                <li [ngClass]="step==5 ? 'active' : 'inactive'">Login/Mot de passe</li>
            </ul>
            <!-- fieldsets -->
            <fieldset *ngIf="step == 1">
                <h2 class="fs-title">{{ "register.doctenacredentials" | translate}}</h2>

                <mat-card-content>
                    <mat-form-field [hidden]="!environmentDisp" appearance="outline" class="matselect">
                        <mat-label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {{ "register.environment" | translate}}
                        </mat-label>
                        <mat-select #environmentSelect [(value)]="environmentDefault">
                            <mat-option
                                    (click)="selectEnvironment(environment)"
                                    *ngFor="let environment of environments" value="{{environment.id}}">
                                {{environment.label}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matPrefix>devices</mat-icon>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.email" | translate}}</mat-label>
                        <mat-icon matPrefix>alternate_email</mat-icon>
                        <input formControlName="email" matInput name="email" required>
                        <mat-error>
                          {{ "register.email_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.token" | translate}}</mat-label>
                        <mat-icon matPrefix>security</mat-icon>
                        <input formControlName="token" matInput name="token" required>
                        <mat-error>
                          {{ "register.token_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>
                <input formControlName="versionId" placeholder="versionId" type="hidden"/>
                <input formControlName="url" placeholder="url" type="hidden"/>

                <input (click)="next()" class="next action-button" name="next" type="button" value="{{ 'register.next' | translate}}"/>
            </fieldset>
        </form>
        <form [formGroup]="userDetails2" id="msform">
            <fieldset *ngIf="step == 2">
                <h2 class="fs-title">{{ "register.practicelabel" | translate}}</h2>

                <mat-card-content>
                    <mat-form-field appearance="outline" class="matselect">
                        <mat-label>{{ "register.practice" | translate}}</mat-label>
                        <mat-select #practiceSelect>
                            <mat-option
                                    (click)="selectPractice(practice)"
                                    *ngFor="let practice of practices" value="{{practice.id}}">
                                {{practice.name}} - {{practice.addressLine1}} - {{practice.zipcode}} - {{practice.city}}
                                - {{practice.country}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matPrefix>my_location</mat-icon>
                    </mat-form-field>
                    <input formControlName="practiceId" name="practiceId" type="hidden"/>
                    <mat-form-field [hidden]="!userDetails2_disp">
                        <mat-label>{{ "register.practicename" | translate}}</mat-label>
                        <mat-icon matPrefix>business</mat-icon>
                        <input formControlName="clientName1" matInput name="clientName1" required>
                        <mat-error>
                            {{ "register.practicename_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field [hidden]="!userDetails2_disp">
                        <mat-label>{{ "register.practicename2" | translate}}</mat-label>
                        <mat-icon matPrefix>business</mat-icon>
                        <input formControlName="clientName2" matInput name="clientName2">
                    </mat-form-field>
                    <!--input type="hidden" formControlName="clientName2"  name="clientName2"/-->
                    <mat-form-field [hidden]="!userDetails2_disp">
                        <mat-label>{{ "register.address" | translate}}</mat-label>
                        <mat-icon matPrefix>home</mat-icon>
                        <input formControlName="clientAddress1" matInput name="clientAddress1" required>
                        <mat-error>
                            {{ "register.address_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field [hidden]="!userDetails2_disp">
                        <mat-label>{{ "register.city" | translate}}</mat-label>
                        <mat-icon matPrefix>location_city</mat-icon>
                        <input formControlName="clientAddress2" matInput name="clientAddress2" required>
                        <mat-error>
                            {{ "register.city_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>

                <input (click)="previous()" class="previous action-button-previous" name="previous" type="button"
                       value="{{ 'register.previous' | translate}}"/>
                <input (click)="next()" class="next action-button" name="next" type="button" value="{{ 'register.next' | translate}}"/>
            </fieldset>
        </form>
        <form [formGroup]="userDetails3" id="msform">
            <fieldset *ngIf="step == 3">
                <h2 class="fs-title">{{ "register.calendarlabel" | translate}}</h2>

                <mat-card-content>
                    <mat-form-field appearance="outline" class="matselect">
                        <mat-label>{{ "register.calendar" | translate}}</mat-label>
                        <mat-select #practiceSelect>
                            <mat-option
                                    (click)="selectCalendar(calendar)"
                                    *ngFor="let calendar of calendars" value="{{calendar.id}}">
                                {{calendar.practiceName}} - {{calendar.agendaName}} - {{calendar.shortName}} -
                                {{calendar.calendarStatus}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matPrefix>perm_contact_calendar</mat-icon>
                    </mat-form-field>

                    <input formControlName="clientId" name="clientId" type="hidden"/>
                    <input formControlName="calendarId" name="calendarId" type="hidden"/>

                    <mat-form-field [hidden]="!userDetails3_disp">
                        <mat-label>{{ "register.calendarname" | translate}}</mat-label>
                        <mat-icon matPrefix>person</mat-icon>
                        <input formControlName="clientShortName" matInput name="clientShortName" required>
                        <mat-error>
                            {{ "register.calendarname_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                </mat-card-content>

                <input (click)="previous()" class="previous action-button-previous" name="previous" type="button"
                       value="{{ 'register.previous' | translate}}"/>
                <input (click)="next()" class="next action-button" name="next" type="button" value="{{ 'register.next' | translate}}"/>
            </fieldset>
        </form>
        <form [formGroup]="userDetails4" id="msform">
            <fieldset *ngIf="step == 4">
                <h2 class="fs-title">{{ "register.complementlabel" | translate}}</h2>

                <mat-card-content>
                    <mat-form-field>
                        <mat-label>{{ "register.providercode" | translate}}</mat-label>
                        <mat-icon matPrefix>confirmation_number</mat-icon>
                        <input formControlName="clientProviderCode" matInput name="clientProviderCode" required>
                        <mat-error>
                            {{ "register.providercode_error" | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.vatnumber" | translate}}</mat-label>
                        <mat-icon matPrefix>format_list_numbered</mat-icon>
                        <input formControlName="clientVatNumber" matInput name="clientVatNumber" required>
                        <mat-error>{{ "register.vatnumber_error" | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.phonenumber" | translate}}</mat-label>
                        <mat-icon matPrefix>phone</mat-icon>
                        <input formControlName="clientPhone" matInput name="clientPhone" required>
                        <mat-error>{{ "register.phonenumber_error" | translate}}</mat-error>
                    </mat-form-field>
                </mat-card-content>

                <input (click)="previous()" class="previous action-button-previous" name="previous" type="button"
                       value="{{ 'register.previous' | translate}}"/>
                <input (click)="next()" class="next action-button" name="next" type="button" value="{{ 'register.next' | translate}}"/>
            </fieldset>
        </form>
        <form [formGroup]="userDetails5" id="msform">
            <fieldset *ngIf="step == 5">
                <h2 class="fs-title">{{ "register.userlabel" | translate}}</h2>

                <mat-card-content>
                    <mat-form-field>
                        <mat-label>{{ "register.username" | translate}}</mat-label>
                        <mat-icon matPrefix>perm_identity</mat-icon>
                        <input formControlName="username" matInput name="username" required>
                        <mat-error>{{ "register.username_error" | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.userid" | translate}}</mat-label>
                        <mat-icon matPrefix>supervised_user_circle</mat-icon>
                        <input formControlName="userid" matInput name="userid" required>
                        <mat-error>{{ "register.userid_error" | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "register.password" | translate}}</mat-label>
                        <mat-icon matPrefix>password</mat-icon>
                        <input formControlName="passwd" matInput name="passwd" required type="password">
                        <mat-error>{{ "register.password_error" | translate}}</mat-error>
                    </mat-form-field>
                </mat-card-content>

                <input (click)="previous()" class="previous action-button-previous" name="previous" type="button"
                       value="{{ 'register.previous' | translate}}"/>
                <input (click)="submit()" class="submit action-button" name="submit" type="submit" value="{{ 'register.signup' | translate}}"/>
            </fieldset>
        </form>
    </div>
</div>
