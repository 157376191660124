import{Pipe, PipeTransform}from '@angular/core'

@Pipe({ name: 'getVisitReason' })

export class GetVisitReasonPipe implements PipeTransform {
transform(vid: number, visitReasons: any = []): string {
    for (var i = 0; i < visitReasons.length; i++) {
        if (visitReasons[i].id === vid) {
            return visitReasons[i].name;
        }
    }
    return "";
  }
}
