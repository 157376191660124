import{Component, OnInit}from '@angular/core';
import {AuthService}from '../_services/auth.service';
import { StorageService}from '../_services/storage.service';
import {Router}from '@angular/router';


@Component({
selector: 'app-login',
templateUrl: './login.component.html',
styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    form: any = {
        username: null,
        password: null
    };
    isLoggedIn = false;
    //isRoleAdmin = false;
    //isRoleUser = false;
    //isRoleReport = false;
    isLoginFailed = false;
    errorMessage = '';
    roles: string = '';

    constructor(private authService: AuthService, private storageService: StorageService, private router: Router) { }

  ngOnInit(): void {
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.roles = this.storageService.getUser().roles;
      //this.isRoleAdmin = this.isUserInRole(this.roles, "ADMIN");
      //this.isRoleUser = this.isUserInRole(this.roles, "USER");
      //this.isRoleReport = this.isUserInRole(this.roles, "REPORT");
    }
  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe({
      next: data => {
        this.storageService.saveUser(data);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.storageService.getUser().roles;
        //this.isRoleAdmin = this.isUserInRole(this.roles, "ADMIN");
        //this.isRoleUser = this.isUserInRole(this.roles, "USER");
        //this.isRoleReport = this.isUserInRole(this.roles, "REPORT");
        //toast.success('Vous etes connecté!');
        //setTimeout(() => {
        this.reloadPage();
        //}, 1000);

      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  reloadPage(): void {
    //window.location.href = "patient";
    this.router.navigate(['patient']);
    //window.location.reload();
  }
}
