<mat-tab-group>
  <mat-tab label="{{ 'patient.calendar.label' | translate}}">
    <mat-card class="example-card">
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>{{ "patient.calendar.date" | translate}}</mat-label>
          <input (ngModelChange)="onChange($event)"
                 [matDatepicker]="picker"
                 [ngModel]="selectedDate" matInput name="apDate">
          <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-divider></mat-divider>
      </mat-card-content>
      <mat-card-footer>
        <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>

    <table [dataSource]="patientsDataSource" class="mat-elevation-z8" mat-table multiTemplateDataRows>
      <ng-container matColumnDef="patientFirstName">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.firstName" | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.patientFirstName}}</td>
      </ng-container>

      <ng-container matColumnDef="patientLastName">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.lastName" | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.patientLastName}}</td>
      </ng-container>

      <ng-container matColumnDef="birthday">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.birthday" | translate}}</th>
        <td *matCellDef="let element" mat-cell>{{element.birthday | getFrenchDate}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.email" | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.email}}</td>
      </ng-container>

      <ng-container matColumnDef="mobilePhone">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.mobile" | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.mobilePhone}}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.startDate" | translate}}</th>
        <td *matCellDef="let element" mat-cell>
          {{element.startDate | getHourFromDate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.endDate" | translate}}</th>
        <td *matCellDef="let element" mat-cell>
          {{element.endDate | getHourFromDate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell> {{ "patient.calendar.status" | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{(element.paidC === 0) ? 'FACTURÉE' : (element.paidC === 1) ?
          'PAYÉE' :
          element.status}}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th *matHeaderCellDef aria-label="row actions" mat-header-cell>&nbsp;</th>
        <td *matCellDef="let element" mat-cell>
          <button
            (click)="loadBillsByApp2(element);(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
            aria-label="expand row"
            mat-icon-button>
            <!-- onNgBillChange(element); -->
            <mat-icon>
              {{(expandedElement === element) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td *matCellDef="let element" [attr.colspan]="displayedColumnsWithExpand.length" mat-cell>
          <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
               class="example-element-detail">
            <table width="100%">
              <tr>
                <td width="40%">
                  <mat-selection-list #bills [multiple]="false">
                    <mat-list-option (click)="onNgBillChange(bill)" *ngFor="let bill of cbills"
                                     [disableRipple]="true" value="{{bill.numBillReport}}">
                      <div style="display:flex; justify-content:space-between;align-items:center">
                        <div style="display:flex;align-items:bottom">
                          <mat-icon matListAvatar>{{(bill.paid === 1) ? 'check_box' :
                            'receipt' }}
                          </mat-icon>

                          <div>
                            <h3 matLine>{{ "patient.calendar.bill" | translate}} {{bill.numBillReport}} -
                              {{bill.serviceName}}</h3>
                          </div>
                        </div>
                        <div style="display:flex;align-items:bottom">
                          <button (click)="onDeleteBill(bill)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-list-option>
                  </mat-selection-list>

                  <mat-form-field appearance="outline" class="matselect" (click)="checkVisitReasons()">
                    <mat-label>{{ "patient.calendar.newbill" | translate}}</mat-label>
                    <mat-select #newBillSelect>
                      <mat-option value=""></mat-option>
                      <mat-option (click)="selectNewService(element.id, visitReason)"
                                  *ngFor="let visitReason of billableVisitReasons"
                                  value="{{visitReason}}">{{visitReason.name}}
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>healing</mat-icon>
                  </mat-form-field>
                </td>
                <td>
                  <form action="{{bilApiUrl}}{{ cappointment }}" id="form{{ cappointment }}" method="post"
                        name="form{{ cappointment }}"
                        target="_blank">
                    <fieldset #billDetail [hidden]="chidden" class="field_set">
                      <legend align="center">
                        {{cvisitReasonName}}
                      </legend>
                      <table width="100%">
                        <tr>
                          <td colspan="7">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="5%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td width="20%">
                            <mat-form-field appearance="outline">
                              <mat-label>{{ "patient.calendar.pretax" | translate}}</mat-label>
                              <input [(ngModel)]="cprice" class="right" currencyFormatter matInput
                                     maxlength="7" name="price"
                                     readonly size="6" value="{{cprice}}">
                              <mat-icon matSuffix>euro_symbol</mat-icon>
                              <mat-error>{{ "patient.calendar.amount_error" | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td width="5%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td width="15%">
                            <mat-form-field appearance="outline">
                              <mat-label>{{ "patient.calendar.vat" | translate}}</mat-label>
                              <input [(ngModel)]="cvat" class="right" currencyFormatter matInput
                                     maxlength="7" name="vat"
                                     readonly size="6" value="{{cvat}}">
                              <mat-icon matSuffix>euro_symbol</mat-icon>
                              <mat-error>{{ "patient.calendar.amount_error" | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td width="5%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td width="25%">
                            <mat-form-field appearance="outline">
                              <mat-label>{{ "patient.calendar.taxincluded" | translate}}</mat-label>
                              <input [(ngModel)]="cpriceWVat" class="right" currencyFormatter matInput
                                     maxlength="7" name="priceWVat"
                                     readonly size="6" value="{{cpriceWVat}}">
                              <mat-icon matSuffix>euro_symbol</mat-icon>
                              <mat-error>{{ "patient.calendar.amount_error" | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td width="5%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                          <td>
                            <fieldset class="field_set">
                              <legend class="legend2">{{ "patient.calendar.paid" | translate}}</legend>
                              <input #paidSelect (click)="cpaid=1;" [checked]="cpaid===1" id="paye"
                                     name="paidSelect" type="radio"
                                     value="1"/>
                              <label for="paye">{{ "patient.calendar.yes" | translate}}</label><br/>
                              <input #paidSelect (click)="cpaid=0;" [checked]="cpaid===0"
                                     id="npaye" name="paidSelect" type="radio"
                                     value="0"/>
                              <label for="npaye">{{ "patient.calendar.no" | translate}}</label>
                              <input [(ngModel)]="cpaid" [value]="paidSelect.value" name="paid"
                                     type="hidden"/>
                              <input [(ngModel)]="cinc" name="inc" type="hidden"
                                     value="{{cinc}}">
                            </fieldset>
                          </td>
                          <td></td>
                          <td>
                            <mat-form-field appearance="outline">
                              <mat-label>{{ "patient.calendar.cash" | translate}}</mat-label>
                              <input (blur)="blurCash()" (click)="clickCash()" [(ngModel)]="ccash"
                                     class="right" currencyFormatter matInput
                                     maxlength="7"
                                     name="cash" required size="6">
                              <mat-icon matSuffix>euro_symbol</mat-icon>
                              <mat-error>{{ "patient.calendar.amount_error" | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td></td>
                          <td>
                            <mat-form-field appearance="outline">
                              <mat-label>{{ "patient.calendar.payconiq" | translate}}</mat-label>
                              <input (blur)="blurPayconiq()" [(ngModel)]="cpayconiq" class="right"
                                     currencyFormatter matInput
                                     maxlength="7"
                                     name="payconiq" required size="6">
                              <mat-icon matSuffix>euro_symbol</mat-icon>
                              <mat-error>{{ "patient.calendar.amount_error" | translate}}</mat-error>
                            </mat-form-field>
                          </td>
                          <td>&nbsp;</td>
                        </tr>

                        <tr>
                          <td class="right" colspan="6">
                            <button
                              (click)="newBillSelect.value='';submitForm(cappointment, cinc, cvisitReasonId, cvisitReasonName, cordre, cprice, cvat, cpaid, ccash, cpayconiq); (cpaid === 0) ? (cstatus='FACTURÉE') : (cstatus='PAYÉE');"
                              [disabled]="cprice=='0' || cprice=='0.00' || cprice=='0,00'"
                              color="accent" extended mat-fab type="submit">
                              <mat-icon>print</mat-icon>
                              {{ "patient.calendar.print" | translate}}
                            </button>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td colspan="7">&nbsp;</td>
                        </tr>
                        <!--
                        <tr *ngIf="element.patientNotes">
                          <td colspan="7">
                            Note patient: {{element.patientNotes}}
                          </td>
                        </tr>
                        <tr *ngIf="element.doctorNotes">
                          <td colspan="7">
                            Note docteur: {{element.doctorNotes}}
                          </td>
                        </tr> -->
                      </table>
                    </fieldset>
                    <br/>
                  </form>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumnsWithExpand" mat-header-row></tr>
      <tr (click)="loadBillsByApp2(element);chidden=true;expandedElement = expandedElement === element ? null : element"
          *matRowDef="let element; columns: displayedColumnsWithExpand;"
          [class.example-expanded-row]="expandedElement === element"
          class="example-element-row"
          mat-row>
        <!-- onNgBillChange(element); -->
      </tr>
      <tr *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" mat-row></tr>
    </table>


  </mat-tab>
  <mat-tab *ngIf="localstorageService.isRoleReport()" label="{{ 'patient.cashbook.label' | translate}}">
    <mat-card class="example-card">
      <mat-card-content>
        <mat-form-field>
          <mat-label>{{ 'patient.cashbook.period' | translate}}</mat-label>
          <mat-date-range-input [formGroup]="rangeCaisse"
                                [rangePicker]="rangeCaissePicker">
            <input formControlName="startCaisse" matStartDate placeholder="{{ 'patient.cashbook.startDate' | translate}}">
            <input (ngModelChange)="onChangeDateCaisse($event)" formControlName="endCaisse" matEndDate
                   placeholder="{{ 'patient.cashbook.endDate' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="rangeCaissePicker" matIconSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #rangeCaissePicker></mat-date-range-picker>
        </mat-form-field>


      </mat-card-content>
      <!--mat-card-actions [hidden]="!(this.bills.length > 0)" align="end">
        <button color="primary" mat-flat-button>Imprimer</button>
      </mat-card-actions-->
      <mat-card-actions align="end">&nbsp;&nbsp;&nbsp;
        <button (click)="openCaisseXLS(dateCaisse)" [hidden]="!(this.bills.length > 0)" color="primary" mat-button
                mat-flat-button>
          <mat-icon>download</mat-icon>
          {{ 'patient.cashbook.download' | translate}}
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar *ngIf="isLoadingCaisse" color="primary" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>

    <table [dataSource]="billsDataSource" class="mat-elevation-z8" mat-table multiTemplateDataRows>
      <!--ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell> Prénom</th>
        <td *matCellDef="let element" mat-cell> {{element.firstName}}
        </td>
      </ng-container-->
      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.cashbook.firstLastName' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.firstName}} {{element.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="datePaiement">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.cashbook.paydate' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.datePaiement}}</td>
      </ng-container>

      <ng-container matColumnDef="cash">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.cashbook.amount' | translate}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.cash}}&euro;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumnsCaisse" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumnsCaisse;" mat-row></tr>
    </table>
  </mat-tab>


  <mat-tab *ngIf="localstorageService.isRoleReport()" label="{{ 'patient.accountbook.label' | translate}}">
    <mat-card class="example-card">
      <mat-card-content>
        <mat-form-field>
          <mat-label>{{ 'patient.accountbook.period' | translate}}</mat-label>
          <mat-date-range-input [formGroup]="rangeCompte"
                                [rangePicker]="rangeComptePicker">
            <input formControlName="start" matStartDate placeholder="{{ 'patient.accountbook.startDate' | translate}}">
            <input (ngModelChange)="onChangeDateCompte($event)" formControlName="end" matEndDate
                   placeholder="{{ 'patient.accountbook.endDate' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="rangeComptePicker" matIconSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #rangeComptePicker></mat-date-range-picker>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions [hidden]="!isLoadingCompte" align="end">
        <button (click)="onChangeDateCompteView(rangeCompte.value)" color="primary" mat-button mat-flat-button>
          <mat-icon>remove_red_eye</mat-icon>
          {{ 'patient.accountbook.result' | translate}}
        </button>
        &nbsp;&nbsp;&nbsp;
        <button (click)="openCompteXLS(rangeCompte.value)" color="primary" mat-button mat-flat-button>
          <mat-icon>download</mat-icon>
          {{ 'patient.accountbook.download' | translate}}
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <!--mat-progress-bar *ngIf="isLoadingCompte" color="primary" mode="indeterminate"></mat-progress-bar-->
      </mat-card-footer>
    </mat-card>
    <table [dataSource]="billsCompteDataSource" class="mat-elevation-z8" mat-table multiTemplateDataRows>
      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.accountbook.firstLastName' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.firstName}} {{element.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="datePaiement">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.accountbook.paydate' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.datePaiement}}</td>
      </ng-container>

      <ng-container matColumnDef="cash">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.accountbook.cash' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.cash}}&euro;
        </td>
      </ng-container>

      <ng-container matColumnDef="payconiq">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.accountbook.payconiq' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.payconiq}}&euro;
        </td>
      </ng-container>

      <ng-container matColumnDef="vat">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.accountbook.vat' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.vat}}&euro;
        </td>
      </ng-container>

      <ng-container matColumnDef="priceWVat">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.accountbook.taxincluded' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.priceWVat}}&euro;
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumnsCompte" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumnsCompte;" mat-row></tr>
    </table>
  </mat-tab>


  <mat-tab *ngIf="localstorageService.isRoleReport()" label="{{ 'patient.unpaidbills.label' | translate}}">
    <mat-card class="example-card">
      <mat-card-content>
        <mat-form-field>
          <mat-label>{{ 'patient.unpaidbills.period' | translate}}</mat-label>
          <mat-date-range-input [formGroup]="rangeUnpaid"
                                [rangePicker]="rangeUnpaidPicker">
            <input formControlName="startUnpaid" matStartDate placeholder="{{ 'patient.unpaidbills.startDate' | translate}}">
            <input (ngModelChange)="onChangeDateUnpaid($event)" formControlName="endUnpaid" matEndDate
                   placeholder="{{ 'patient.unpaidbills.endDate' | translate}}">
          </mat-date-range-input>
          <!--mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint-->
          <mat-datepicker-toggle [for]="rangeUnpaidPicker" matIconSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #rangeUnpaidPicker></mat-date-range-picker>
        </mat-form-field>
        <!--p>Selected range: {{rangeCompte.value | json}}</p-->
      </mat-card-content>
      <mat-card-actions [hidden]="!(this.billsUnpaid.length > 0)" align="end">
        <button (click)="openUnpaidXLS(rangeUnpaid.value)" color="primary" mat-button mat-flat-button>
          <mat-icon>download</mat-icon>
          {{ 'patient.unpaidbills.download' | translate}}
        </button>
      </mat-card-actions>
      <!--mat-card-footer>
        <mat-progress-bar *ngIf="isLoadingUnpaid" color="primary" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer-->
    </mat-card>
    <table [dataSource]="billsUnpaidDataSource" class="mat-elevation-z8" mat-table multiTemplateDataRows>
      <!--ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell> Prénom</th>
        <td *matCellDef="let element" mat-cell> {{element.firstName}}
        </td>
      </ng-container-->
      <ng-container matColumnDef="firstName">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.unpaidbills.firstLastName' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.firstName}} {{element.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="dateService">
        <th *matHeaderCellDef mat-header-cell> {{ 'patient.unpaidbills.appodate' | translate}}</th>
        <td *matCellDef="let element" mat-cell> {{element.dateService}}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.unpaidbills.pretax' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.price}}&euro;
        </td>
      </ng-container>

      <ng-container matColumnDef="vat">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.unpaidbills.vat' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.vat}}&euro;
        </td>
      </ng-container>

      <ng-container matColumnDef="priceWVat">
        <th *matHeaderCellDef class="right" mat-header-cell> {{ 'patient.unpaidbills.taxincluded' | translate}}</th>
        <td *matCellDef="let element" class="right" mat-cell>
          {{element.priceWVat}}&euro;
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumnsUnpaid" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumnsUnpaid;" mat-row></tr>
    </table>
  </mat-tab>
</mat-tab-group>
