<mat-tab-group>
    <mat-tab label="{{ 'param.price.label' | translate}}">
        <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table multiTemplateDataRows>

            <ng-container matColumnDef="code">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.code' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                    <input (blur)="saveData(element);" [(ngModel)]="element.code" maxlength="10" name="code"
                           size="10" value="{{element.code}}">
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.name' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                    <input (blur)="saveData(element);" [(ngModel)]="element.name" maxlength="50" name="name"
                           size="40" value="{{element.name}}">
                </td>
            </ng-container>

            <ng-container matColumnDef="name_bill">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.name_bill' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                    <input (blur)="saveData(element);" [(ngModel)]="element.name_bill" maxlength="100" name="name_bill"
                           size="60" value="{{element.name_bill}}">
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.pretax' | translate}}</th>
                <td *matCellDef="let element" mat-cell>{{element.price}}</td>
            </ng-container>

            <ng-container matColumnDef="vatRate">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.vatrate' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                    <input [(ngModel)]="element.vatRate" class="right" currencyFormatter maxlength="10" name="vatRate"
                           required size="10" value="{{element.vatRate}}">
                </td>
            </ng-container>

            <ng-container matColumnDef="priceWVat">
                <th *matHeaderCellDef mat-header-cell> {{ 'param.price.taxincluded' | translate}}</th>
                <td *matCellDef="let element" mat-cell>
                    <input (blur)="saveData(element);" [(ngModel)]="element.priceWVat" class="right" currencyFormatter maxlength="10"
                           name="priceWVat" required size="10" value="{{element.priceWVat}}">
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>


    </mat-tab>
</mat-tab-group>



