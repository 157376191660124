import { Component } from '@angular/core';
import { StorageService } from '../_services/storage.service';
import { AuthService } from '../_services/auth.service';

import { TranslateService } from '@ngx-translate/core';
// set NODE_OPTIONS=--openssl-legacy-provider

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
selector: 'app-navbar',
templateUrl: './navbar.component.html',
styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
    siteLanguage = 'Français';
    siteLangCode = 'fr';
    languageList = [
      { code: 'fr', label: 'Français' },
      { code: 'de', label: 'Deutsch' },
      { code: 'lu', label: 'Lëtzebuergesch' },
      { code: 'pt', label: 'Português' },
      { code: 'en', label: 'English' },
      { code: 'es', label: 'Español' }
    ];
    isLoggedIn = false;
    username?: string;
    localstorageService: any;

    constructor(private storageService: StorageService, private authService: AuthService, private translate: TranslateService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
      this.localstorageService = this.storageService;
      this.matIconRegistry.addSvgIcon("fr", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/fr2.svg"));
      this.matIconRegistry.addSvgIcon("en", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/en2.svg"));
      this.matIconRegistry.addSvgIcon("de", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/de2.svg"));
      this.matIconRegistry.addSvgIcon("lu", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/lu2.svg"));
      this.matIconRegistry.addSvgIcon("es", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/es2.svg"));
      this.matIconRegistry.addSvgIcon("pt", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/i18n/pt2.svg"));
    }

  changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = this.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();
      //window.alert(selectedLanguage);
    if (selectedLanguage) {
      this.siteLanguage = selectedLanguage;
      this.siteLangCode = localeCode;
      this.translate.use(localeCode);
    }
    const currentLanguage = this.translate.currentLang;
    console.log('currentLanguage', currentLanguage);
  }

  ngOnInit(): void {
    this.isLoggedIn = this.storageService.isLoggedIn();
    if (this.isLoggedIn) {
      const user = this.storageService.getUser();
      this.username = user.username;
    }
  }
}
